@charset "UTF-8";
.ContactPage {
  text-align: left;
  padding: 3em 55px 5em 201px; }
  @media screen and (max-width: 1024px) {
    .ContactPage {
      padding: 3em 1em 5em 1em; } }
  .ContactPage .Message {
    margin-bottom: 2em; }
    .ContactPage .Message p {
      margin: 0; }
  .ContactPage form {
    width: 70%; }
    @media screen and (max-width: 1024px) {
      .ContactPage form {
        width: 90%; } }
    .ContactPage form .Field {
      margin-bottom: 1.5em; }
    .ContactPage form label {
      margin-bottom: 3px;
      float: none;
      font-size: 1.10em;
      font-weight: 700;
      display: block; }
      .ContactPage form label span {
        color: #aaa;
        font-size: 0.7em; }
        .ContactPage form label span:before {
          content: "　"; }
    .ContactPage form input:focus {
      outline: 0; }
    .ContactPage form input {
      border: 1px solid #ccc;
      width: 100%;
      font-size: 1.1em;
      padding: 0.5em; }
    .ContactPage form textarea {
      border: 1px solid #ccc;
      width: 100%;
      height: 300px;
      resize: none;
      padding: 0.5em;
      font-size: 1.1em; }
      .ContactPage form textarea:focus {
        outline: 0; }
    .ContactPage form button {
      color: white;
      background: #ccc;
      border-radius: 3px;
      padding: 1em;
      width: 100px; }
