.PrivacyPage {
  text-align: left;
  padding: 3em 55px 5em 201px;
  @media screen and (max-width: 1024px) {
    padding: 3em 1em 5em 1em;
  }

  h4 {
    font-size: 1.25em;
  }

  ul {
    list-style-type: decimal;
  }
}
