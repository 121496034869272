body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  background: #eee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h3 {
  font-size: 1.5em;
  width: 100%;
}

h4 {
  font-size: 1.25em;
  width: 100%;
}
h5 {
  font-size: 1.0em;
  margin-bottom: 0.25em;
}

.Header-Image {
  background: white;
  padding: 1em;
  display: -webkit-flex;
  display: flex; }

.Header-Menu {
  background: #8B0000;
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr; }
  @media not screen and (max-width: 1024px) {
    .Header-Menu {
      padding: 0 10em; } }
  .Header-Menu a {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .Header-Menu a:hover {
      background: #770000; }
  .Header-Menu a, .Header-Menu a * {
    color: white;
    text-decoration: none;
    font-weight: bold; }

.Footer {
  border-top: 1px solid #eee; }
  .Footer .Info {
    display: grid;
    padding: 2em 3em;
    grid-gap: 2em; }
    @media not screen and (max-width: 1024px) {
      .Footer .Info {
        grid-template-columns: 1fr 1fr; } }
    @media screen and (max-width: 1024px) {
      .Footer .Info {
        grid-template-rows: 100px 1fr; } }
    .Footer .Info h4:before, .Footer .Info h4:after {
      content: " - "; }
    .Footer .Info .About table {
      border-spacing: 0;
      border-collapse: separate;
      border-bottom: 1px solid #eee;
      margin: 0 0 1.5em 0;
      width: 100%;
      text-align: left; }
    .Footer .Info .About td {
      border-top: 1px solid #eee;
      padding: 0.75em;
      margin: 0 0 1.5em 0; }
      @media screen and (max-width: 1024px) {
        .Footer .Info .About td.Label {
          width: 80px; } }
  .Footer .License {
    background-color: #404040;
    border-top: 1px solid #d9d9d9;
    color: #fff;
    padding: 24px 34px 24px 34px;
    min-height: 50px;
    display: grid;
    grid-template-rows: 1fr 1em; }
    .Footer .License .CopyWrite {
      text-align: left;
      grid-row: 2 /3; }

.App {
  text-align: center;
  margin: 0 auto;
  max-width: 1180px;
  padding: 0;
  background: white;
  color: #444; }

.Top .Concept {
  background-image: url("/assets/top/top_image_1.jpg");
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 600px; }
  @media screen and (max-width: 1024px) {
    .Top .Concept {
      height: 300px; } }
  .Top .Concept .Message {
    padding: 1em;
    background: #ffffffee;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-flow: column;
            flex-flow: column;
    width: 50%;
    height: 30%; }
    @media screen and (max-width: 1024px) {
      .Top .Concept .Message {
        width: 80vw;
        height: 50%; } }
    .Top .Concept .Message p {
      font-size: 1.1em; }

.Top .Business {
  text-align: left;
  padding: 4em 2em;
  display: grid;
  grid-gap: 3em; }
  @media not screen and (max-width: 1024px) {
    .Top .Business {
      grid-template-columns: 1fr 400px 1fr 400px 1fr; } }
  @media screen and (max-width: 1024px) {
    .Top .Business {
      grid-template-rows: 3fr 2fr; } }
  .Top .Business img {
    width: 100%;
    height: auto; }
  .Top .Business h3, .Top .Business h4 {
    text-align: center; }
  @media not screen and (max-width: 1024px) {
    .Top .Business .Purpose {
      grid-column: 2 / 3; } }
  .Top .Business .Purpose ul {
    padding: 0 1em;
    list-style-type: decimal; }
  .Top .Business .Purpose li {
    margin: 1em 0; }
  @media not screen and (max-width: 1024px) {
    .Top .Business .Service {
      grid-column: 4 / 5; } }

@charset "UTF-8";
.ContactPage {
  text-align: left;
  padding: 3em 55px 5em 201px; }
  @media screen and (max-width: 1024px) {
    .ContactPage {
      padding: 3em 1em 5em 1em; } }
  .ContactPage .Message {
    margin-bottom: 2em; }
    .ContactPage .Message p {
      margin: 0; }
  .ContactPage form {
    width: 70%; }
    @media screen and (max-width: 1024px) {
      .ContactPage form {
        width: 90%; } }
    .ContactPage form .Field {
      margin-bottom: 1.5em; }
    .ContactPage form label {
      margin-bottom: 3px;
      float: none;
      font-size: 1.10em;
      font-weight: 700;
      display: block; }
      .ContactPage form label span {
        color: #aaa;
        font-size: 0.7em; }
        .ContactPage form label span:before {
          content: "\3000"; }
    .ContactPage form input:focus {
      outline: 0; }
    .ContactPage form input {
      border: 1px solid #ccc;
      width: 100%;
      font-size: 1.1em;
      padding: 0.5em; }
    .ContactPage form textarea {
      border: 1px solid #ccc;
      width: 100%;
      height: 300px;
      resize: none;
      padding: 0.5em;
      font-size: 1.1em; }
      .ContactPage form textarea:focus {
        outline: 0; }
    .ContactPage form button {
      color: white;
      background: #ccc;
      border-radius: 3px;
      padding: 1em;
      width: 100px; }

.PrivacyPage {
  text-align: left;
  padding: 3em 55px 5em 201px; }
  @media screen and (max-width: 1024px) {
    .PrivacyPage {
      padding: 3em 1em 5em 1em; } }
  .PrivacyPage h4 {
    font-size: 1.25em; }
  .PrivacyPage ul {
    list-style-type: decimal; }

