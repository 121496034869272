.Footer {
  border-top: 1px solid #eee;

  .Info {
    display: grid;
    padding: 2em 3em;
    grid-gap: 2em;

    @media not screen and (max-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: 1024px) {
      grid-template-rows: 100px 1fr;
    }

    h4:before, h4:after {
      content: " - ";
    }

    .About {

      table {
        border-spacing: 0;
        border-collapse: separate;
        border-bottom: 1px solid #eee;
        margin: 0 0 1.5em 0;
        width: 100%;
        text-align: left;
      }

      td {
        border-top: 1px solid #eee;
        padding: 0.75em;
        margin: 0 0 1.5em 0;

        &.Label {
          @media screen and (max-width: 1024px) {
            width: 80px;
          }
        }
      }
    }

  }

  .License {
    background-color: #404040;
    border-top: 1px solid #d9d9d9;
    color: #fff;
    padding: 24px 34px 24px 34px;
    min-height: 50px;
    display: grid;
    grid-template-rows: 1fr 1em;

    .CopyWrite {
      text-align: left;
      grid-row: 2 /3;
    }
  }

}
