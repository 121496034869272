.App {
  text-align: center;
  margin: 0 auto;
  max-width: 1180px;
  padding: 0;
  background: white;
  color: #444;
}

$business_section_width: 400px;

.Top {

  .Concept {
    background-image: url('/assets/top/top_image_1.jpg');
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    height: 600px;
    @media screen and (max-width: 1024px) {
      height: 300px;
    }


    .Message {
      padding: 1em;
      background: #ffffffee;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      width: 50%;
      height: 30%;
      @media screen and (max-width: 1024px) {
        width: 80vw;
        height: 50%;
      }

      p {
        font-size: 1.1em;
      }
    }
  }

  .Business {
    text-align: left;
    padding: 4em 2em;
    display: grid;
    grid-gap: 3em;
    @media not screen and (max-width: 1024px) {
      grid-template-columns: 1fr $business_section_width 1fr $business_section_width 1fr;
    }
    @media screen and (max-width: 1024px) {
      grid-template-rows: 3fr 2fr;
    }

    img {
      width: 100%;
      height: auto;
    }

    h3, h4 {
      text-align: center;
    }

    .Purpose {
      @media not screen and (max-width: 1024px) {
        grid-column: 2 / 3;
      }

      ul {
        padding: 0 1em;
        list-style-type: decimal;
      }

      li {
        margin: 1em 0;
      }
    }

    .Service {
      @media not screen and (max-width: 1024px) {
        grid-column: 4 / 5;
      }
    }
  }

}

