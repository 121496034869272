$background-color: #8B0000;
$background-color-hover: #7A0000;

.Header {
}

.Header-Image {
    background: white;
    padding: 1em;
    display: flex;
}

.Header-Menu {
    background: $background-color;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media not screen and (max-width: 1024px) {
        padding: 0 10em;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: darken($background-color-hover, 0.5);
        }
    }

    a, a * {
        color: white;
        text-decoration: none;
        font-weight: bold;
    }
}
